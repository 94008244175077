:root {
    --site-black: #000;
    --site-white: #fff;
    --site-primary: #0000ed;
    --site-primary-light: #e8e8ff;

    --site-primary-cta-background-color: #80e096;
    --site-primary-cta-hover-background-color: #96eaa9;
    --site-primary-cta-color: #131313;

    --validation-color: #fe0000;

    --input-border-color: #ddd;

    --fw-normal: 400;
    --fw-bold: 700;
    --fw-black: 900;

    --body-font-size: 14px;
    --subtitle-font-size: 16px;

    /* Spacing unit i.e calc(--spacing-unit * 4) */
    --spacing-unit: 4px;

    /* Elevated Cart Variables */
    --cart-font-family: var(--brand-font);
    --cart-font-family-bold: var(--brand-font);
    --cart-font-family-italic: var(--brand-font);
    --cart-heading-font-family: var(--brand-font);
    --cart-button-font-family: var(--brand-font);
    --font-family-normal: var(--brand-font);
    --font-family-bold: var(--brand-font);
    --font-family: var(--brand-font);
    --font-family-italic: var(--brand-font);
    --product-line-item-font-family-bold: var(--brand-font);
    --wishlist-button-text-transform: none;
    --wishlist-button-letter-spacing: 0;
    --wishlist-summary-header-text-transform: capitalize;

    --swiper-preloader-color: var(--site-primary);

    --site-membership-badge-background-colour: var(--site-primary);

    --personalisation-modal-cta-background-color: var(--site-primary-cta-background-color);
    --personalisation-modal-cta-color: var(--site-primary-cta-color);
    --personalisation-modal-cta-border-color: transparent;
    --personalisation-modal-cta-hover-background-color: var(--site-primary-cta-hover-background-color);
    --personalisation-modal-cta-hover-color: var(--site-primary-cta-color);
    --personalisation-modal-cta-hover-border-color: transparent;
    --personalisation-modal-cta-font-weight: var(--fw-bold);
    --personalisation-loader-color: var(--site-primary);

    --printess-loader-color: var(--site-primary);
    --printess-personalised-pdp-container: var(--site-primary);
    --printess-pdp-tick-outer: var(--site-primary);
    --printess-personalised-font-color: var(--site-primary);
}
