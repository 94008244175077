.ProdDetails,
#hotspotModal {
    .printess-button-cta {
        letter-spacing: 0;
        font-size: 16px;
        text-transform: capitalize;
        border-radius: 0;

        &:hover {
            border: 1px solid var(--site-primary);
        }
    }

    .personalisation-applied {
        border-radius: 0;
    }
}
