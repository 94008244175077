html:has(.cms-placements),
html:has(.is-cms-content-page) {
    scrollbar-gutter: unset !important;
}

.CmsMenu_Container {
    /* ---------------------------------------------------------------- */
    /* Spacing */
    /* ---------------------------------------------------------------- */
    --spacing-1: 4px;
    --spacing-2: calc(var(--spacing-1) * 2);
    --spacing-3: calc(var(--spacing-1) * 3);
    --spacing-4: calc(var(--spacing-1) * 4);
    --spacing-5: calc(var(--spacing-1) * 5);
    --spacing-6: calc(var(--spacing-1) * 6);
    --spacing-7: calc(var(--spacing-1) * 7);
    --spacing-8: calc(var(--spacing-1) * 8);
    --spacing-9: calc(var(--spacing-1) * 9);
    --spacing-10: calc(var(--spacing-1) * 10);
    --spacing-11: calc(var(--spacing-1) * 11);
    --spacing-12: calc(var(--spacing-1) * 12);
    --spacing-13: calc(var(--spacing-1) * 13);
    --spacing-14: calc(var(--spacing-1) * 14);
    --spacing-15: calc(var(--spacing-1) * 15);
    --spacing-16: calc(var(--spacing-1) * 16);
    --spacing-17: calc(var(--spacing-1) * 17);
    --spacing-18: calc(var(--spacing-1) * 18);
    --spacing-19: calc(var(--spacing-1) * 19);
    --spacing-20: calc(var(--spacing-1) * 20);
    --spacing-21: calc(var(--spacing-1) * 21);
    --spacing-22: calc(var(--spacing-1) * 22);
    --spacing-23: calc(var(--spacing-1) * 23);
    --spacing-24: calc(var(--spacing-1) * 24);
    --spacing-25: calc(var(--spacing-1) * 25);
    --negative-spacing-1: calc(var(--spacing-1) * -1);
    --negative-spacing-2: calc(var(--spacing-2) * -1);
    --negative-spacing-3: calc(var(--spacing-3) * -1);
    --negative-spacing-4: calc(var(--spacing-4) * -1);
    --negative-spacing-5: calc(var(--spacing-5) * -1);
    --negative-spacing-6: calc(var(--spacing-6) * -1);
    --negative-spacing-7: calc(var(--spacing-7) * -1);
    --negative-spacing-8: calc(var(--spacing-8) * -1);
    --negative-spacing-10: calc(var(--spacing-10) * -1);
    --negative-spacing-12: calc(var(--spacing-12) * -1);
    --negative-spacing-24: calc(var(--spacing-24) * -1);

    /* ---------------------------------------------------------------- */
    /* Typography */
    /* ---------------------------------------------------------------- */
    --general-font-stack-fallback: arial, helvetica, sans-serif;
    --heading-font-stack-fallback: Roboto-Variable, var(--general-font-stack-fallback);
    --heading-font-stack: var(--heading-font, var(--heading-font-stack-fallback));
    --body-font-stack-fallback: Roboto-Variable, var(--general-font-stack-fallback);
    --body-font-stack: var(--body-font, var(--body-font-stack-fallback));

    /* ---------------------------------------------------------------- */
    /* Colors: brand */
    /* ---------------------------------------------------------------- */
    --primary-light-rgb: 232, 232, 255;
    --primary-light: rgb(var(--primary-light-rgb));
    --primary-main-rgb: 0, 0, 237;
    --primary-main: rgb(var(--primary-main-rgb));
    --primary-dark-rgb: 0, 0, 190;
    --primary-dark: rgb(var(--primary-dark-rgb));
    --secondary-light-rgb: 237, 51, 51;
    --secondary-light: rgb(var(--secondary-light-rgb));
    --secondary-main-rgb: 237, 0, 0;
    --secondary-main: rgb(var(--secondary-main-rgb));
    --secondary-dark-rgb: 213, 0, 0;
    --secondary-dark: rgb(var(--secondary-dark-rgb));
    --tertiary-light-rgb: 171, 235, 186;
    --tertiary-light: rgb(var(--tertiary-light-rgb));
    --tertiary-main-rgb: 128, 224, 150;
    --tertiary-main: rgb(var(--tertiary-main-rgb));
    --tertiary-dark-rgb: 115, 202, 135;
    --tertiary-dark: rgb(var(--tertiary-dark-rgb));

    /* ---------------------------------------------------------------- */
    /* Colors: grey tones */
    /* ---------------------------------------------------------------- */
    --grey-800-rgb: 66, 66, 66;
    --grey-800: rgb(var(--grey-800-rgb));
    --grey-700-rgb: 28, 28, 28;
    --grey-700: rgb(var(--grey-700-rgb));
    --grey-600-rgb: 65, 69, 73;
    --grey-600: rgb(var(--grey-600-rgb));
    --grey-500-rgb: 91, 93, 94;
    --grey-500: rgb(var(--grey-500-rgb));
    --grey-400-rgb: 125, 128, 130;
    --grey-400: rgb(var(--grey-400-rgb));
    --grey-300-rgb: 196, 196, 196;
    --grey-300: rgb(var(--grey-300-rgb));
    --grey-200-rgb: 231, 234, 236;
    --grey-200: rgb(var(--grey-200-rgb));
    --grey-100-rgb: 248, 248, 248;
    --grey-100: rgb(var(--grey-100-rgb));

    /* ---------------------------------------------------------------- */
    /* Colors: common */
    /* ---------------------------------------------------------------- */
    --common-white-rgb: 255, 255, 255;
    --common-white: rgb(var(--common-white-rgb));
    --common-black-rgb: 0, 0, 0;
    --common-black: rgb(var(--common-black-rgb));

    /* ---------------------------------------------------------------- */
    /* Miscellaneous */
    /* ---------------------------------------------------------------- */
    --container-width: min(100vw, 1920px);
    --focus-outline-main: 2px solid var(--primary-main);
    --focus-input-main: 1px solid var(--primary-main);
}

nav#topMenu .CmsMenu_Container {
    /* ----------------------------------------------------------------- */
    /*  CMS styling  */
    /* ----------------------------------------------------------------- */
    .GenericMenu_container {
        max-width: 1504px;
        margin: 0 auto;
        padding: var(--spacing-4) 0;
        text-align: left;
    }

    .GenericMenu_container a {
        padding: 5px;
    }

    .GenericMenu_menu {
        display: grid;
        gap: var(--spacing-3);
        grid-template-columns: repeat(6, minmax(0, 1fr));
        margin: 0;
        padding: 0 var(--spacing-5) var(--spacing-4);
        list-style: none;
    }

    /* ---------------------------------------------------------------------------------------------------- */
    /*   ColumnMenu                                                                                         */
    /* ---------------------------------------------------------------------------------------------------- */
    .ColumnMenu_grid {
        column-gap: var(--spacing-4);
    }

    .ColumnMenu_grid > div {
        break-inside: avoid-column;
    }

    .ColumnMenu_title {
        display: inline-block;
        margin-bottom: var(--spacing-1);
        text-align: left;
        font-size: 1.15em;
        font-family: var(--brand-font);
        font-weight: var(--fw-bold);
    }

    .ColumnMenu_grid .ProductMenu_Container > a {
        display: inline-block;
        color: var(--common-black);

        &:not(.disable-link):hover {
            color: var(--primary-main);
        }
    }

    .ColumnMenu_grid .ProductMenu_Container .ProductMenu_menu.hasLinks {
        margin-bottom: 1em;
    }

    .SubMenuWrapper .ColumnMenu_col-span-1 .MenuLink_link,
    .SubMenuWrapper .ColumnMenu_col-span-2 .MenuLink_link,
    .SubMenuWrapper .ColumnMenu_col-span-3 .MenuLink_link,
    .SubMenuWrapper .ColumnMenu_col-span-4 .MenuLink_link,
    .SubMenuWrapper .ColumnMenu_col-span-5 .MenuLink_link,
    .SubMenuWrapper .ColumnMenu_col-span-6 .MenuLink_link {
        font-size: 1em;
    }

    /* Disable unused-unknown-classnames, as the following classes are invoked dynamically. */
    /* stylelint-disable @fx/unknown-unused-classnames */

    .ColumnMenu_col-span-1 {
        grid-column: span 1 / span 1;
    }

    .ColumnMenu_col-span-2 {
        grid-column: span 2 / span 2;
    }

    .ColumnMenu_col-span-3 {
        grid-column: span 3 / span 3;
    }

    .ColumnMenu_col-span-4 {
        grid-column: span 4 / span 4;
    }

    .ColumnMenu_col-span-5 {
        grid-column: span 5 / span 5;
    }

    .ColumnMenu_col-span-6 {
        grid-column: span 6 / span 6;
    }

    .ColumnMenu_grid-cols-1 {
        column-count: 1;
    }

    .ColumnMenu_grid-cols-2 {
        column-count: 2;
    }

    .ColumnMenu_grid-cols-3 {
        column-count: 3;
    }

    .ColumnMenu_grid-cols-4 {
        column-count: 4;
    }

    .ColumnMenu_grid-cols-5 {
        column-count: 5;
    }

    .ColumnMenu_grid-cols-6 {
        column-count: 6;
    }

    .ProductMenu_menu {
        margin: 0;
        list-style: none;
    }

    .ProductMenu_menu > a {
        margin: 0;
        padding: 0 5px;
    }

    .ProductMenu_menu > li > a {
        margin: 0;
        padding: 0;
    }

    .SubMenuWrapper div[data-testid="product-menu"] > a {
        font-size: 1em;
    }

    .ProductMenu_title {
        font-size: 1.05em;
        font-weight: var(--fw-bold);
        font-family: var(--brand-font);
    }

    ul li.ProductMenu_gap {
        margin-top: 20px;
    }

    .BannerMenu_caption {
        position: absolute;
        left: var(--spacing-4);
        bottom: var(--spacing-4);
        padding: 2px 3px;
        background-color: var(--common-white);
    }

    .Image_image {
        transition-duration: 300ms;
        transition-property: transform;
    }

    .BannerMenu_link {
        display: block;
        &:focus {
            outline: var(--focus-outline-main);
            outline-offset: -2px;
        }
    }

    .BannerMenu_menu {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .BannerMenu_menuitem {
        position: relative;
        overflow: hidden;
    }

    .QuickLinkMenu_container {
        grid-column: span 1 / span 1;
        margin-bottom: 1.5rem;
    }

    .QuickLinkMenu_menu {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .QuickLinkMenu_menu > .QuickLinkMenuItem {
        margin-bottom: var(--spacing-2);
    }

    .QuickLinkMenu_menu > .QuickLinkMenuItem:last-child {
        margin-bottom: unset;
    }

    .QuickLinkMenu_title {
        font-family: var(--brand-font);
        font-weight: var(--fw-bold);
        font-size: 1.05em;
        text-transform: uppercase;
    }

    .MenuLink_link {
        display: block;
        padding: 2px 5px;
        color: var(--common-black);
        text-decoration: none;
    }

    .MenuLink_link:not(.disable-link):hover {
        color: var(--primary-main);
    }

    .MenuLink_link:focus {
        outline: var(--focus-outline-main);
        outline-offset: 1px;
    }

    .MenuLink_link:focus:hover {
        outline: none;
    }

    .TabMenu_content {
        border-top: 1px solid var(--grey-300);
        display: flex;
        background-color: #fff;
        max-height: calc(100vh - 100px);
        overflow-y: auto;
        margin: 0;
    }

    .TabMenu_link {
        color: inherit;
        text-decoration: none;
    }

    .TabMenu_menu {
        display: flex;
        column-gap: var(--spacing-4);
        flex-direction: row;
        justify-content: center;
        margin: 0;
        padding: var(--spacing-8) 0 0;
        list-style: none;
    }

    .TabMenu_menuitem {
        border-bottom: 2px solid transparent;
        color: var(--grey-300);
        &:focus {
            outline: var(--focus-outline-main);
            outline-offset: 2px;
        }

        &.open {
            border-bottom-color: var(--primary-main);
            color: var(--common-black);
        }
    }

    .TabMenu_menuitem .GenericMenu_container {
        display: none;
    }

    .TabMenu_menuitem.open .GenericMenu_container {
        display: flex;
    }

    .Link_root:focus-visible {
        outline: var(--focus-outline-main);
        outline-offset: 1px;
    }

    .LogoMenu_image {
        aspect-ratio: auto;
        max-height: 75px;
        max-width: 100px;
        height: auto;
        width: auto;
    }

    .LogoMenu_menu {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, auto));
        margin: 0;
        padding: 2em 0 0 0;
    }

    li.root .SubMenuWrapper > ul li.newTag a {
        display: inline-block;
        width: auto;
    }

    /* Themed links such as Black Friday, Christmas and Sale */
    li.root.christmas,
    li.root.sale {
        & > a,
        &:hover > a {
            background-color: #ed0000;
            color: #fff;
        }
    }

    li a,
    .ColumnMenu_title {
        line-height: 1.5em;
    }

    .ColumnMenu_title.titleSpacer {
        display: inline-block;
        padding: 1em;
    }

    /* TEMP: This is to ensure the Brands logo menu contains four columns */
    .ColumnMenu_grid:has(.LogoMenu_menu) {
        display: grid;
    }

    .ColumnMenu_grid-cols-2 .LogoMenu_menu {
        grid-template-columns: repeat(4, minmax(0, auto));
    }

    .ColumnMenu_grid-cols-3 .LogoMenu_menu {
        grid-template-columns: repeat(6, minmax(0, auto));
    }

    .ColumnMenu_grid-cols-4 .LogoMenu_menu {
        grid-template-columns: repeat(8, minmax(0, auto));
    }

    .ColumnMenu_grid-cols-6 .LogoMenu_menu {
        grid-template-columns: repeat(12, minmax(0, auto));
    }

    li.root .SubMenuWrapper .LogoMenu_menu > .LogoMenu_link {
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        padding: 0px 10px 30px;
    }

    a.disable-link {
        cursor: text;
    }
}

#mp-menu.CmsMenu_Container {
    /* TEMP: This is to ensure the USC top border is styled appropriatly */
    li[id="mob-usc"] > a.MobMenChevron::before {
        content: "";
        border: 0;
        border-top: 1px solid #0000ed;
        border-image-source: linear-gradient(to right, #ee3424 50%, #0000ed 50%);
        border-image-slice: 1;
        top: 0;
        left: 0;
        position: absolute;
        height: 1px;
        width: 100%;
    }

    /* Mobile new tag style */
    .mp-level ul li.newTag .menuitemtext::after {
        vertical-align: text-bottom;
        display: inline;
        line-height: inherit;
        font-weight: var(--fw-bold);
        font-family: var(--brand-font);
        padding: 0.25em 0.5em;
        margin-left: 0.5em;
    }

    .mp-level .christmas,
    .mp-level .sale {
        &,
        & > .menuitemtext.MobMenChevron {
            background-color: #ed0000;
            color: #fff;

            &::after {
                background-position: -393px -319px;
            }
        }
    }

    a.disable-link {
        pointer-events: none;
    }
}

/* ---------------------------------------------------------------- */
/* Colors */
/* ---------------------------------------------------------------- */
.Typography_text-black {
    color: var(--common-black);
}

.Typography_text-grey-300 {
    color: var(--grey-300);
}

.Typography_text-grey-400 {
    color: var(--grey-400);
}

.Typography_text-grey-500 {
    color: var(--grey-500);
}

.Typography_text-primary {
    color: var(--primary-main);
}

.Typography_text-secondary {
    color: var(--secondary-main);
}

.Typography_text-white {
    color: var(--common-white);
}

.Typography_text-error {
    color: var(--error-main);
}

/* ---------------------------------------------------------------- */
/* Font Weights */
/* ---------------------------------------------------------------- */
.Typography_font-regular {
    font-weight: var(--fw-normal);
}

.Typography_font-bold {
    font-weight: var(--fw-bold);
}

/* ---------------------------------------------------------------- */
/* Miscellaneous */
/* ---------------------------------------------------------------- */
.break-word {
    word-break: break-word;
}

.gutter-bottom {
    padding-bottom: var(--spacing-1);
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.cms-placements {
    display: block;
    position: relative;
    max-width: 1920px;

    [class*="Carousel_slides_"] {
        padding-bottom: var(--spacing-6);

        @media (min-width: 768px) {
            padding-bottom: var(--spacing-10);
        }
    }
}

body {
    box-sizing: border-box;
}

#__next,
.cms-placements {
    #content-x div {
        &[data-testid="container"],
        &[data-testid="club-finder-block"],
        &[data-testid="tabbed-quick-links-block"],
        &[data-testid="brand-block-container"],
        &[data-testid="cards-block"] {
            margin: auto;
        }
    }

    a,
    a:active,
    a:focus,
    a:link,
    a:visited,
    a:hover,
    a p:hover,
    h1,
    h1 span,
    h2,
    h3,
    h4,
    h5,
    p,
    label,
    input,
    optgroup,
    select,
    textarea {
        color: var(--color);
    }

    ul,
    ul li {
        list-style: initial;
    }

    ol,
    ol li {
        list-style: decimal;
    }

    ul,
    ol {
        padding-left: var(--spacing-4, 16px);
    }

    button {
        &:not([data-testid]) {
            color: var(--color);
            background-color: var(--bg-color);
            border-color: var(--border-color);

            &:hover {
                background-color: var(--bg-color, transparent);
            }
        }

        &[data-testid] {
            &[data-testid="tabbed-quick-links-block-button"]:hover {
                color: unset;
                background-color: unset;
            }

            &[data-testid="accordion-group"] {
                margin: 0 12px;
                border-top: 1px solid var(--grey-300);

                @media (min-width: 1022px) {
                    margin: 0;
                }
            }

            &[data-testid="accordion-summary"] {
                border-color: var(--grey-300);

                &:hover {
                    color: unset;
                    background-color: unset;
                }
            }
        }

        &[data-testid="arrow-right"],
        &[data-testid="arrow-left"] {
            color: var(--grey-500);
        }
    }

    [class*="Carousel_counteractMargins"] {
        width: calc(100% + var(--spacing-8));
        margin-left: var(--negative-spacing-4);
        margin-right: var(--negative-spacing-4);

        @media (min-width: 768px) {
            width: calc(100% + var(--spacing-12));
            margin-left: var(--negative-spacing-6);
            margin-right: var(--negative-spacing-6);
        }

        @media (min-width: 992px) {
            width: calc(100% + var(--spacing-24));
            margin-left: var(--negative-spacing-12);
            margin-right: var(--negative-spacing-12);
        }

        @media (min-width: 1440px) {
            width: calc(100% + var(--spacing-48));
            margin-left: var(--negative-spacing-24);
            margin-right: var(--negative-spacing-24);
        }
    }

    [class*="Carousel_slides_"] {
        @supports (not (selector(::-webkit-scrollbar))) {
            scrollbar-color: var(--primary-main) var(--common-white);
            scrollbar-width: thin;
        }
    }

    [class*="MarkdownBlock_center"] {
        display: flex;
        justify-content: center;
    }
}
