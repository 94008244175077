.basket-urgency-message {
    &-header {
        font-family: var(--brand-font);
        font-weight: var(--fw-bold);
    }
}

#divBagItems .basket-urgency-message {
    background-color: var(--site-primary);

    .global-icon {
        svg path {
            fill: var(--site-white);
        }
    }

    &-header,
    &-text {
        color: var(--site-white);
    }
}
