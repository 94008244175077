#CardDeletionModal .btnYesNoContainer {
    display: flex;
    flex-wrap: wrap;

    .buttonYesNoAction,
    .btnYes {
        order: 1;
        width: 50%;
        padding: 15px 0;
        margin: 0 auto;
    }

    .buttonYesNoAction.btnNo {
        order: 2;
        width: 100%;
        margin-top: 5px;
        background-color: transparent;
        color: #000 !important;
    }
}
